<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Salud')}} </h3>
		</div>
		<div class="p-col-12 p-md-5" v-if="!mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h5>Nº {{$t('Póliza')}}</h5>
					</template>
					<template #content>
						<div class="p-grid p-align-center vertical-container">
							<div class="p-col-4">
								INI-P-HEA-
							</div>
							<div class="p-col-6">
								<InputText v-model="poliza" style="width: 120px;" type="number" :placeholder="$t('Número')" min="0" @keyup.enter="Buscar"/>
							</div>
							<div class="p-col-2">
								-RX
							</div>
						</div>					
					</template>
					<template #footer>
						<Button :label="$t('Siguiente')" icon="pi pi-arrow-right" class="p-button-secondary" @click="Buscar"/>
					</template>
				</Card>
			</div>
		</div>
		
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Póliza')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-6">
								{{$t('Número')}}: <strong>{{seleccion.codigo}}</strong>			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<strong>{{seleccion.salud.tipopoliza_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<strong>{{seleccion.salud.tipopoliza_name}}</strong> 			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.estado==6"/>
								<strong>{{seleccion.estado_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.estado==6"/>
								<strong>{{seleccion.estado_name}}</strong> 			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>
							{{$t('Información Básica')}}
							<Button icon="pi pi-check-circle" class="p-button-rounded p-button-secondary" @click="AgregB"/>
						</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-3">
								{{$t('Número ID')}}: <strong>{{seleccion.code}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Localidad')}}: <strong>{{seleccion.salud.localidadn}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Nombre Completo')}}: <strong>{{seleccion.nombre}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono móvil')}}: <strong>{{seleccion.celular}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono privado')}}: <strong>{{seleccion.tlflocal}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Correo')}}: <strong>{{seleccion.pcorreo}}</strong>			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="p-grid">
				<div class="p-col-12" v-for="(item, index) in beneficiarios" :key="index">
					<div class="card">
						<Card>
							<template #title>
								<h4>
									{{$t('Información Básica')}} ({{$t('Beneficiario')}} {{index+1}})
								</h4>
							</template>
							<template #content>
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-col-11">
										<div class="p-grid">
											<div class="p-field p-col-12 p-md-2">
												<label>{{$t('Número ID')}}</label>
												<InputText v-model="item.code" type="text"/>
											</div>
											<div class="p-field p-col-12 p-md-10">
												<label>{{$t('Nombre Completo')}}</label>
												<InputText v-model="item.nombre" type="text"/>
											</div>
											<div class="p-field p-col-12 p-md-2">
												<label>{{$t('Teléfono móvil')}}</label>
												<InputMask v-model="item.celular" mask="+99999 999999"/>
												<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
												<small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
											</div>
											<div class="p-field p-col-12 p-md-2">
												<label>{{$t('Teléfono privado')}}</label>
												<InputMask v-model="item.tlflocal" mask="+99999 999999"/>
												<small class="p-error" v-if="i18n.locale() == 'es'">Incluir código del país</small>
												<small class="p-error" v-if="i18n.locale() == 'en'">Include country code</small>                    
											</div>                      
											<div class="p-field p-col-12 p-md-8">
												<label>{{$t('Correo')}}</label>
												<InputText v-model="item.correo" type="email"/>
											</div>
										</div>
									</div>
									<div class="p-field p-col-12 p-md-1">
										<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary" @click="AgregBenef" v-if="index==0"/>
										<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DelBenef(item)" v-if="index>0"/>
									</div>
								</div>
							</template>
						</Card>
					</div>
				</div>
			</div>
		</div>
		
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:  
							<strong :style="'color:'+estado.color" v-if="i18n.locale() == 'es'">
								{{estado.nombre}}
							</strong>
							<strong :style="'color:'+estado.color" v-if="i18n.locale() == 'en'">
								{{estado.name}}
							</strong>
						</h4>
					</template>
					<template #content>		
								
						<div class="p-fluid p-formgrid p-grid">						
							<div class="p-field p-col-4">
								<label>{{$t('Fecha en que ocurrió el incidente')}}</label>
								<InputMask v-model="informacion.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" style="width:250px;"/>
								<!--<InputText v-model="informacion.fecha" type="date" style="width:250px;"/>-->
								<small v-show="validationErrors.fecha" class="p-error">{{$t('required')}}</small>
							</div>
							<div class="p-field p-col-4">
								<label>{{$t('Monto de Reserva')}}: </label>
								<InputNumber id="horizontal" v-model="informacion.estimacion" showButtons buttonLayout="horizontal" 
									decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
									decrementButtonIcon="pi pi-minus" :min="informacion.reserva" mode="currency" :currency="$store.state.currency" style="max-width:250px;"/>
							</div>
							<div class="p-field p-col-4">
								<label>{{$t('Forence')}}</label>
								<Dropdown id="state" v-model="informacion.forence" :options="forences" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;"></Dropdown>
								<small v-show="validationErrors.forence" class="p-error">{{$t('required')}}</small>
							</div>
							<div class="p-field p-col-2" v-if="informacion.forence.code>0">
								<label>{{$t('Notificar al forence')}}</label><br>
								<Checkbox v-model="informacion.notificar" :binary="true" />
							</div>
						</div>
					</template>
					<template #footer>
						<Button :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" @click="Guardar()" />
						<Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="Mostrar"/>
						<Dropdown class="p-mr-2 p-mb-2" id="state" v-model="estado" :options="estados" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;"></Dropdown>
						<Dropdown class="p-mr-2 p-mb-2" id="state" v-model="estado2" :options="estados2" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" style="width:250px;" v-if="estado.code==2"></Dropdown>
					</template>
				</Card>
			</div>
		</div>
		
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				polizas: [],
				seleccion: {},
				mostrar: false,
				forences: [],
				estados: [],
				estados2: [],
				beneficiarios: [],
				validationErrors: {},
				informacion: {
					lesiones: false,
					reemplazo: false,
					recuperacion: false,
					fecha: null,
					reserva: 0,
					estimacion: 0,
					forence: {nombre: 'En Evaluación', name: 'In Evaluation', code: 0},
					notificar: false,
				},
				estado: null,
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				this.asunto = [];
				this.polizas = [];
				this.beneficiarios = [];
				this.mostrar = false;
				const Consulta = new API('Reclamos');
				Consulta.Ini('Claim3').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					if (result.consult.forences){
						this.forences = result.consult.forences;
					}
					if (result.consult.estados){
						this.estados = result.consult.estados;
						this.estado = this.estados[0];
					}
					this.informacion.reserva =  result.consult.reserva;       
					this.informacion.estimacion =  result.consult.reserva;
					this.beneficiarios.push({
						code: null,
						nombre: null,
						correo: null,
						celular: null,
						tlflocal: null,
					});
					this.$store.commit('Loading');
				}); 
			},
			Seleccionado(data) {
				this.seleccion = data;
				this.mostrar = true;
			},
			AgregB() {
				this.beneficiarios[0]={
					code: this.seleccion.code,
					nombre: this.seleccion.nombre,
					correo: this.seleccion.correo,
					celular: this.seleccion.salud.celular,
					tlflocal: this.seleccion.salud.tlflocal,
				};
			},
			AgregBenef() {
				this.beneficiarios.push({
					code: null,
					nombre: null,
					correo: null,
					celular: null,
					tlflocal: null,
				});
			},
			DelBenef(selec) {
				this.beneficiarios = this.beneficiarios.filter(val => val !== selec);
				if(this.beneficiarios.length==0){
					this.beneficiarios.push({
						code: null,
						nombre: null,
						correo: null,
						celular: null,
						tlflocal: null,
						tipo: null,
						marca: null,
						modelo: null,
						año: null,
						placa: null,
					});
				}
			},
			Buscar() {
				this.seleccion = {};
				this.$store.commit('Loading');
				const Consulta = new API('Reclamos');
				Consulta.Procesar('Claim3',{
					caso: 	'BuscarReg',
					poliza: this.poliza,
				}).then(response => {
					//this.$store.state.error = response.result;	
					if(response.result){
						this.seleccion = response.result;
						this.mostrar = true;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('Error')+' '+this.$t('Número Póliza'), 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			Guardar() {
				if (this.validateForm()) {
					this.$store.commit('Loading');
					const Consulta = new API('Reclamos');  
					this.informacion.fecha = Consulta.fdate(this.informacion.fecha);        
					Consulta.Procesar('Claim3',{
						caso: 	'INS',
						claim: this.seleccion,
						informacion: this.informacion,
						beneficiarios: this.beneficiarios,
						estado: this.estado,
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success',
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.$store.commit('Loading');
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error'), 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
				}
			},
			validateForm() {
				if (this.informacion.fecha.length!=10)
				this.validationErrors['fecha'] = true;
				else
				delete this.validationErrors['fecha'];
				if (this.informacion.reserva==null)
				this.validationErrors['reserva'] = true;
				else
				delete this.validationErrors['reserva'];
				if (this.informacion.forence==null)
				this.validationErrors['forence'] = true;
				else
				delete this.validationErrors['forence'];

				return !Object.keys(this.validationErrors).length;
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
